<template>
  <div class="d-flex flex-column h-100">
    <header>
      <nav-bar />
    </header>
<!--    <main class="flex-shrink-0">-->
    <main class="flex-grow-0 p-0 m-0">
      <router-view/>
    </main>
    <footer class="footer mt-auto ">
      <footer-bar/>
    </footer>
  </div>
</template>

<script>
import NewNav from "@/components/nav/NewNav.vue";
import FooterBar from "@/components/nav/FooterBar.vue";

// https://github.com/robcresswell/vue-material-design-icons
// https://pictogrammers.com/library/mdi/
export default {
  components: {
    NavBar: NewNav,
    FooterBar
  }
}
</script>

<style lang="scss">
main > .container {
  padding: 70px 15px 0;
}

/*@media (min-width: 500px) {
  main > .container {
    padding: 150px 15px 0;
  }
}*/
/*@media (min-width: 412px) {
  main > .container {
    padding: 140px 15px 0;
  }
}

@media (min-width: 570px) {
  main > .container {
    padding: 140px 15px 0;
  }
}*/

/*@media (min-width: 576px) {
  main > .container {
    padding: 70px 15px 0;
  }
}*/

/* `md` applies to small devices (landscape phones, less than 768px) */
/*@media (min-width: 767px) {
  main > .container {
    padding: 70px 15px 0;
  }
}*/

/* `lg` applies to medium devices (tablets, less than 992px) */
/*@media (min-width: 991px) {

}*/

/* `xl` applies to large devices (desktops, less than 1200px) */
/*@media (min-width: 1199px) {

}*/

/* `xxl` applies to x-large devices (large desktops, less than 1400px) */
/*@media (min-width: 1399px) {

}*/



/*@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300..700&display=swap');
.comfortaa {
  font-family: "Comfortaa", sans-serif;
  font-optical-sizing: auto;
  font-weight: normal;
  font-style: normal;
}

p {
  font-family: "Comfortaa", sans-serif;
  font-optical-sizing: auto;
  font-weight: normal;
  font-style: normal;
}*/



/*@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro:ital,wght@0,200..900;1,200..900&display=swap');
p {
  font-family: "Source Code Pro", monospace;
  font-optical-sizing: auto;
  font-weight: 600;
  font-style: normal;
}*/

/*@import url('https://fonts.googleapis.com/css2?family=Montserrat+Alternates:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
p {
  font-family: "Montserrat Alternates", sans-serif;
  font-weight: 300;
  font-style: normal;
}*/

@import url('https://fonts.googleapis.com/css2?family=Red+Hat+Text:ital,wght@0,300..700;1,300..700&display=swap');
/*body { Nope
  font-family: "Red Hat Text", sans-serif;
  font-optical-sizing: auto;
  font-weight: 500;
  font-style: normal;
}*/
/*p { Yep?
 font-family: "Red Hat Text", sans-serif;
 font-optical-sizing: auto;
 font-weight: 500;
 font-style: normal;
}*/

/*@import url('https://fonts.googleapis.com/css2?family=Bai+Jamjuree:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');
p {
  font-family: "Bai Jamjuree", sans-serif;
  font-weight: 400;
  font-style: normal;
}*/
/*
.source-code-pro {
  font-family: "Source Code Pro", monospace;
  font-optical-sizing: auto;
  font-weight: 200;
  font-style: normal;
}*/

/*#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}*/

/*:root {
  --primary-color: red;
}*/
</style>
