// import axios from "axios";

export default {
  namespaced: true,
  state: {
    cart: [],
  },
  getters: {
    cart: (state) => state.cart,
  },
  mutations: {
    cart(state, cart) {
      state.cart = cart
    },
  },
  actions: {
    async emptyCart(context) {
      let cart = context.getters["cart"];
      cart.length = 0
    },
    async addToCartNew(context, cartItem) {
      // await context.dispatch("loadCart");
      let cart = context.getters["cart"];

      // console.log('cart: ' + JSON.stringify(cart));
      // console.log('cartItem to add: ' + JSON.stringify(cartItem));

      const product = cartItem.product;
      const qty = cartItem.qty;

      // console.log('adding productId to cart: ' + product.productId);


      // Find the item in the cart by productId
      const existingItem = cart.find((item) => {
        // console.log('item in cart: ' + JSON.stringify(item));
        const itemProduct = item.product;
        // console.log('item in cart product: ' + JSON.stringify(itemProduct));
        return itemProduct.productId === product.productId
      });

      if (existingItem) {

        // console.log('item already in cart: ' + JSON.stringify(existingItem))

        //  if qty is different - adjust cart qty to match
        if (existingItem.qty !== qty) {
          existingItem.qty = qty;

          return 'updated'
        } else {
          // console.log('item already in cart and addQuantities match, so we ignore request')
          throw {
            errorType: 'AlreadyInCart',
            message: 'Item already in cart and addQuantities match',
            item: existingItem
          };
        }

        // If the item exists, update its quantity
        // existingItem.qty += cartItem.qty;
        //  todo: raise an error - ask user if they would like to increase quantity in cart
        // throw new Error('Item already in cart');
        /*throw {
          errorType: 'AlreadyInCart',
          message: 'Item already in cart',
          item: existingItem
        };*/
      } else {
        // If the item doesn't exist, add it to the cart
        // console.log('adding to cart: ' + JSON.stringify(cartItem));
        cart.push(cartItem);

        return 'added'
      }
    },
    async incrementQty(context, productId){
      console.log('increment')
      let cart = context.getters["cart"];
      // Find the item in the cart by productId
      const existingItem = cart.find((item) => {
        // console.log('item in cart: ' + JSON.stringify(item));
        const itemProduct = item.product;
        // console.log('item in cart product: ' + JSON.stringify(itemProduct));
        return itemProduct.productId === productId
      });

      if (existingItem) {
        existingItem.qty++;
        // console.log('cartItem after increment: ' + JSON.stringify(existingItem));
      }


    },
    async decrementQty(context, productId){
      // console.log('decrement ' + productId)
      let cart = context.getters["cart"];
      // Find the item in the cart by productId
      const existingItem = cart.find((item) => {
        // console.log('item in cart: ' + JSON.stringify(item));
        const itemProduct = item.product;
        // console.log('item in cart product: ' + JSON.stringify(itemProduct));
        return itemProduct.productId === productId
      });

      if (existingItem) {
        existingItem.qty--;
        if (existingItem.qty <= 0) cart.splice(cart.indexOf(existingItem), 1);
        // console.log('cartItem after decrement: ' + JSON.stringify(existingItem));
      } else {
        console.error('Item not found in cart to decrement')
      }
    }
  }
}
