import axios from "axios";

export default {
    namespaced: true,
    state: {
        loaded: false,
        products: []
    },
    getters: {
        products: (state) => state.products,
        loaded: (state) => state.loaded
    },
    mutations: {
        products(state, products) {
            state.products = products
        },
        loaded(state, loaded) {
            state.loaded = loaded
        }
    },
    actions: {
        async loadProducts(context) {
            if (!context.getters["loaded"]) {
                const catalogUrl = process.env.VUE_APP_API_URL + "/catalog";
                const response = (await axios.get(catalogUrl, {
                    headers: {
                        'x-api-key': process.env.VUE_APP_API_KEY
                    }
                }));

                const products = response.data;
                await context.commit("products", products);
                await context.commit("loaded", true);
            }
        }
    },
    modules: {
    }
}
