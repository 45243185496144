<template>
  <div class="container">
<!--  <footer class="container-fluid bg-transparent">-->
    <div class="row mt-5">
      <div class="col-12 text-center">
        <a href="https://www.facebook.com/MossBeachLife"
           target="_blank"
           rel="noopener noreferrer"
           class="m-0 p-0 text-dark">
          <i class="fab fa-facebook fa-lg"></i>
        </a>
      </div>
    </div>
    <div class="row">
      <div class="col-12 text-center m-0 p-0">
        <p class="pt-1 m-0"><copyright-icon :size="15"/> MossBeachLife.com</p>
        <p v-if="enhanced" class="p-0 m-0">{{ width }} x {{height}}</p>
      </div>
    </div>

<!--  </footer>-->
  </div>
</template>
<script>
import CopyrightIcon from 'vue-material-design-icons/Copyright.vue';
import {computed, onMounted, ref} from "vue";
import {useRoute} from "vue-router";

// https://github.com/robcresswell/vue-material-design-icons
// https://pictogrammers.com/library/mdi/
export default {
  components: {
    CopyrightIcon,
  },
  setup() {

    const route = useRoute();

    const width = ref(0);
    const height = ref(0);

    const enhanced = computed(() => {
      return route.query.mode === 'enhanced'
    })

    const updateDimensions = () => {
      height.value = window.innerHeight;
      width.value = window.innerWidth;
    };

    onMounted(() => {
      updateDimensions();
      window.addEventListener('resize', updateDimensions)
    });

    return {
      width,
      height,
      enhanced
    }
  }
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;
  }
}

.fa-facebook:hover {
  color: #3b5998;
}
</style>
