import { createRouter, createWebHistory } from 'vue-router'
// import BootstrapView from "@/views/BootstrapView.vue";
// import BootstrapGrid from "@/components/bootstrap/BootstrapGrid.vue";
// import BootstrapContainer from "@/components/bootstrap/BootstrapContainer.vue";
// import BootstrapForms from "@/components/bootstrap/BootstrapForms.vue";
// import BootstrapListgroups from "@/components/bootstrap/BootstrapListgroups.vue";
// import BootstrapCards from "@/components/bootstrap/BootstrapCards.vue";
// import BootstrapFlexbox from "@/components/bootstrap/BootstrapFlexbox.vue";
// import BootstrapDarkmode from "@/components/bootstrap/BootstrapDarkmode.vue";
// import BootstrapNavbar from "@/components/bootstrap/BootstrapNavbar.vue";
// import BootstrapButtons from "@/components/bootstrap/BootstrapButtons.vue";
// import BootstrapBreakpoints from "@/components/bootstrap/BootstrapBreakpoints.vue";
// import BootstrapSizing from "@/components/bootstrap/BootstrapSizing.vue";
// import BootstrapSpacing from "@/components/bootstrap/BootstrapSpacing.vue";
// import BootstrapBackground from "@/components/bootstrap/BootstrapBackground.vue";
// import BootstrapDisplay from "@/components/bootstrap/BootstrapDisplay.vue";
// import BootstrapTypography from "@/components/bootstrap/BootstrapTypography.vue";
// import BootstrapFormvalidation from "@/components/bootstrap/BootstrapFormvalidation.vue";
// import BootstrapAlerts from "@/components/bootstrap/BootstrapAlerts.vue";
// import BootstrapTables from "@/components/bootstrap/BootstrapTables.vue";
// import BootstrapBreadcrumbs from "@/components/bootstrap/BootstrapBreadcrumbs.vue";
// import BootstrapDropdowns from "@/components/bootstrap/BootstrapDropdowns.vue";
// import BootstrapAccordion from "@/components/bootstrap/BootstrapAccordion.vue";
// import BootstrapCarousel from "@/components/bootstrap/BootstrapCarousel.vue";
// import BootstrapToasts from "@/components/bootstrap/BootstrapToasts.vue";
// import BootstrapSpinners from "@/components/bootstrap/BootstrapSpinners.vue";
// import BootstrapModals from "@/components/bootstrap/BootstrapModals.vue";
// import BootstrapTooltips from "@/components/bootstrap/BootstrapTooltips.vue";

const routes = [
  {
    path: '/',
    name: 'home',
    // component: HomeView
    component: () => import(/* webpackChunkName: "gallery" */ '../views/NewHome.vue')
  },
  {
    path: '/gallery',
    name: 'gallery',
    component: () => import(/* webpackChunkName: "gallery" */ '../views/GalleryView.vue')
  },
  {
    path: '/store',
    name: 'store',
    component: () => import(/* webpackChunkName: "store" */ '../views/NewStore.vue')
  },
  {
    path: '/cart',
    name: 'cart',
    component: () => import(/* webpackChunkName: "checkout" */ '../views/NewCart.vue'),
  },
  /*{
    path: '/orders',
    name: 'orders',
    component: () => import(/!* webpackChunkName: "orders" *!/ '../views/SearchOrdersView.vue')
  },*/
  {
    path: '/orders',
    name: 'orders',
    component: () => import(/* webpackChunkName: "orders" */ '../views/OrderView.vue')
  },
  {
    path: '/contact',
    name: 'contact',
    component: () => import(/* webpackChunkName: "contact" */ '../views/ContactView.vue')
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/bootstrap',
    name: 'bootstrap',
    component: () => import('../views/BootstrapView.vue'),
    children: [
      {
        path: 'container',
        name: 'bootstrap-container',
        // component: BootstrapContainer
        component: () => import(/* webpackChunkName: "bootstrap-container" */ '../components/bootstrap/BootstrapContainer.vue'),
      },
      {
        path: 'typography',
        name: 'bootstrap-typography',
        component: () => import(/* webpackChunkName: "bootstrap-typography" */ '../components/bootstrap/BootstrapTypography.vue'),
      },
      {
        path: 'display',
        name: 'bootstrap-display',
        component: () => import(/* webpackChunkName: "bootstrap-display" */ '../components/bootstrap/BootstrapDisplay.vue'),
      },
      {
        path: 'colors',
        name: 'bootstrap-colors',
        component: () => import(/* webpackChunkName: "bootstrap-colors" */ '../components/bootstrap/BootstrapBackground.vue'),
      },
      {
        path: 'spacing',
        name: 'bootstrap-spacing',
        component: () => import(/* webpackChunkName: "bootstrap-spacing" */ '../components/bootstrap/BootstrapSpacing.vue'),
      },
      {
        path: 'sizing',
        name: 'bootstrap-sizing',
        component: () => import(/* webpackChunkName: "bootstrap-sizing" */ '../components/bootstrap/BootstrapSizing.vue'),
      },
      {
        path: 'breaks',
        name: 'bootstrap-breaks',
        component: () => import(/* webpackChunkName: "bootstrap-breaks" */ '../components/bootstrap/BootstrapBreakpoints.vue'),
      },
      {
        path: 'buttons',
        name: 'bootstrap-buttons',
        component: () => import(/* webpackChunkName: "bootstrap-buttons" */ '../components/bootstrap/BootstrapButtons.vue'),
      },
      {
        path: 'nav',
        name: 'bootstrap-nav',
        component: () => import(/* webpackChunkName: "bootstrap-nav" */ '../components/bootstrap/BootstrapNavbar.vue'),
      },
      {
        path: 'darkmode',
        name: 'bootstrap-darkmode',
        component: () => import(/* webpackChunkName: "bootstrap-darkmode" */ '../components/bootstrap/BootstrapDarkmode.vue'),
      },
      {
        path: 'grid',
        name: 'bootstrap-grid',
        component: () => import(/* webpackChunkName: "bootstrap-grid" */ '../components/bootstrap/BootstrapGrid.vue'),
      },
      {
        path: 'flexbox',
        name: 'bootstrap-flexbox',
        component: () => import(/* webpackChunkName: "bootstrap-flexbox" */ '../components/bootstrap/BootstrapFlexbox.vue'),
      },
      {
        path: 'cards',
        name: 'bootstrap-cards',
        component: () => import(/* webpackChunkName: "bootstrap-cards" */ '../components/bootstrap/BootstrapCards.vue'),
      },
      {
        path: 'lists',
        name: 'bootstrap-lists',
        component: () => import(/* webpackChunkName: "bootstrap-lists" */ '../components/bootstrap/BootstrapListgroups.vue'),
      },
      {
        path: 'forms',
        name: 'bootstrap-forms',
        component: () => import(/* webpackChunkName: "bootstrap-forms" */ '../components/bootstrap/BootstrapForms.vue'),
      },
      {
        path: 'validation',
        name: 'bootstrap-validation',
        component: () => import(/* webpackChunkName: "bootstrap-validation" */ '../components/bootstrap/BootstrapAlerts.vue'),
      },
      {
        path: 'alerts',
        name: 'bootstrap-alerts',
        component: () => import(/* webpackChunkName: "bootstrap-alerts" */ '../components/bootstrap/BootstrapAlerts.vue'),
      },
      {
        path: 'tables',
        name: 'bootstrap-tables',
        component: () => import(/* webpackChunkName: "bootstrap-tables" */ '../components/bootstrap/BootstrapTables.vue'),
      },
      {
        path: 'breadcrumbs',
        name: 'bootstrap-breadcrumbs',
        component: () => import(/* webpackChunkName: "bootstrap-breadcrumbs" */ '../components/bootstrap/BootstrapBreadcrumbs.vue'),
      },
      {
        path: 'dropdowns',
        name: 'bootstrap-dropdowns',
        component: () => import(/* webpackChunkName: "bootstrap-dropdowns" */ '../components/bootstrap/BootstrapDropdowns.vue'),
      },
      {
        path: 'accordion',
        name: 'bootstrap-accordion',
        component: () => import(/* webpackChunkName: "bootstrap-accordion" */ '../components/bootstrap/BootstrapAccordion.vue'),
      },
      {
        path: 'carousel',
        name: 'bootstrap-carousel',
        component: () => import(/* webpackChunkName: "bootstrap-carousel" */ '../components/bootstrap/BootstrapCarousel.vue'),
      },
      {
        path: 'toasts',
        name: 'bootstrap-toasts',
        component: () => import(/* webpackChunkName: "bootstrap-toasts" */ '../components/bootstrap/BootstrapToasts.vue'),
      },
      {
        path: 'spinners',
        name: 'bootstrap-spinners',
        component: () => import(/* webpackChunkName: "bootstrap-spinners" */ '../components/bootstrap/BootstrapSpinners.vue'),
      },
      {
        path: 'modals',
        name: 'bootstrap-modals',
        component: () => import(/* webpackChunkName: "bootstrap-modals" */ '../components/bootstrap/BootstrapModals.vue'),
      },
      {
        path: 'modals',
        name: 'bootstrap-tooltips',
        component: () => import(/* webpackChunkName: "bootstrap-tooltips" */ '../components/bootstrap/BootstrapTooltips.vue'),
      },
    ]
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: '/'
  }

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
